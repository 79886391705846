body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom right, #cbd7c3, #77c2be);
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 100%;
}

.CheckoutContainer, .PayPalCheckoutContainer, .BinanceCheckoutContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
  margin-left: auto;
  margin-right: auto;
  border: 2px dashed #6aa9a7;
  margin-top: 10px;
  display: flex;
}

.CheckoutContainer div {
  flex: 1;
}

.CheckoutBinanceLogo {
  max-width: 50px;
  margin: 0;
  padding: 0;
  float: left;
}

.PayPalCheckoutIcon, .BinanceCheckoutIcon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 350px;
  min-height: 100px;
  cursor: pointer;
}

.BinanceCheckoutButton {
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  padding: 4px 15px 4px 4px;
  box-sizing: border-box;
  background-color: #ffc439;
  font-weight: 600;
  line-height: 30px;
  cursor: pointer;
  font-size: 10pt;
}

.BinanceMobileAppIcon {
  max-width: 90px!important;
  margin-top: 5px;
  margin-bottom: 6px;
}

.BinanceWebIcon {
  max-width: 100%; /* Убедитесь, что изображение не выходит за границы контейнера */
  max-height: 90%;
}

.BinanceCheckoutFormPaymentMethod {
  width: 33%;
  float: left;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DoNotMoveAlert {
  color: red;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 9pt;
}

.BinanceCheckoutFormPaymentMethod a {
  text-decoration: none;
  font-size: 10pt;
  color: rgb(34, 51, 50);
}

.BinanceCheckoutFormPaymentMethodContent {
  text-align: center;
}

.BinanceCheckoutFormPaymentMethod img {
  max-width: 100px;
}

.PayPalCheckoutIcon {
  background-image: url(../public/img/paypal_express_checkout.png)
}

.BinanceCheckoutIcon {
  background-image: url(../public/img/binance_checkout_icon.png)
}

.TravelerDataForm {
  width: 100%;
  min-height: 75px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding: 4px;
  border: 2px dashed #6aa9a7;
  border-radius: 2px;
}

.TravelerDataForm div {
  float: left;
  box-sizing: border-box;
}
.TravelerDataForm input {
  width: 100%;
}

.TravelerDataForm input, .TravelerDataForm select {
  margin-left: 2px;
  margin-right: 2px;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(26, 58, 57, 0.539);
  border: 1px solid rgb(68, 108, 107);
  border-radius: 2px;
  font-size: 12pt;
  font-family: 'Open Sans', sans-serif;
  padding: 4px;
}

.MainContent {
  max-width: 700px;
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  padding-left: 2px;
  padding-right: 2px;
}

.Footer {
  bottom: 0;
  width: 100%;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Footer > .InfoText {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  font-size: 0.75rem;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 50px;
}

.Footer a {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  font-size: 0.85rem;
  margin: 4px 4px 10px;
  color: rgb(13, 40, 39);
}

.Header {
  width: 100%;
  background-color: #226B69;
  position: fixed;
  top: 0;
  z-index: 101;
}

.Header h1 {
  font-family: "Luckiest Guy", cursive;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  font-size: 24pt;
  text-align: center;
  text-shadow: 0 0 5px #51a29f, 0 0 10px #42d0cc;
  margin: 0;
}

.FlightsResultContainer {
  width: 100%;
  margin: auto;
  min-height: 200px;
  border-radius: 2px;
  border: 4px dashed #6aa9a7;
  box-sizing: border-box;
  display: flex;
}

.LoaderText {
  width: 100%;
  margin: auto;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  font-size: 0.8rem;
}

.GetYourTicketDiv {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 18pt;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 52px;
}

.FlightsInfoDiv {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-size: 8pt;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.InfoTableContainer {
  width: 100%;
  box-sizing: border-box;
  margin: auto;
}

.BinanceCheckoutForm {
  width: 100%;
  text-align: center;
  font-size: 10pt;
}

.InfoTableContainer table {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  font-size: 1.15rem;
}

.InfoTableContainer table td {
  vertical-align: top;
}

.MainInfoImage {
  float: right;
  margin-left: 20px;
  width: 150px;
}

.Spacer5 {
  width: 100vw;
  height: 5vh;
}

.DarkGreenColor {
  color: rgb(68, 108, 107);
}

.SearchForm {
  width: 100%;
  margin: 20px auto auto;
}

.QuestionDiv {
  font-weight: 600;
  font-size: 16pt;
}

.AnswerDiv {
  font-weight: 400;
  font-size: 12pt;
  margin-top: 20px;
  margin-bottom: 20px;
}

.SearchTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.SearchTable td,
.SearchTable th {
  padding: 4px;
  border: none;
}

.SearchTableSpan {
  font-weight: 500;
}

.searchInput {
  border: 1px solid rgb(68, 108, 107);
  border-radius: 2px;
  font-size: 12pt;
  font-family: 'Open Sans', sans-serif;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(26, 58, 57, 0.539);
  float: left;
  margin-bottom: 4px;
  margin-right: 4px;
}

.RoundTypeDiv {
  margin-bottom: 20px;
}

.RoundTypeLabel {
  margin-right: 14px;
}

.CityInput {
  width: 100%;
  padding-left: 10px;
  padding-right: 34px;
  font-size: 11pt !important;
}

.calendarHack {
  float: left!important;
}

.Width30 {
  width: 30%;
}

.Width35 {
  width: 35%;
}

.Width20 {
  width: 20%;
}

.Width10 {
  width: 10%;
}

.Width100 {
  width: 100%;
}

.CitySearchResultTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.CitySearchResultTable td {
  border: none;
  position: relative;
}

.SearchBtn {
  background: rgb(68, 108, 107);
  border-radius: 2px;
  color: white;
  cursor: pointer;
  border: 2px solid rgb(68, 108, 107);
  box-shadow: 0 0 4px rgba(26, 58, 57, 0.539);
  font-family: "Open Sans", sans-serif;
  text-align: center;
  width: 99%;
  box-sizing: border-box;
}

.fromCityDiv, .toCityDiv, .datePickerDiv {
  width: 33.33%;
  float: left;
  position: relative;
}

.searchSubmitDiv {
  position: relative;
}

.SearchResultDiv {
  background-color: white;
  border-radius: 2px;
  border: 2px solid rgb(68, 108, 107);
  padding: 10px;
  width: 98%;
  box-sizing: border-box;
  position: absolute;
  top: 60px;
  font-size: 10pt;
  max-height: 80vh;
  overflow-y: scroll;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.486);
  z-index: 10;
}

.react-datepicker-popper {
  z-index: 2!important;
}

.CitySearchResultDiv {
  font-weight: 500;
  cursor: pointer;
}

.AirportSearchResultDiv {
  padding-left: 12px;
  cursor: pointer;
  margin-bottom: 2px;
}

.AirportSearchResultDiv:hover {
  background-color: rgb(235, 242, 242);
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  padding-left: 10px;
  font-size: 11pt !important;
  width: 100%;
}

.CityInputDiv {
  position: relative;
}

.CityInputReset {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 24px;
  height: 24px;
  background-image: url("img/reset.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}

.FlightsFormContainer {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(68, 108, 107, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.486);
  border-radius: 2px;
  padding: 10px;
  box-sizing: border-box;
}

.FlightBlock {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.486);
  border-radius: 2px;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.FlightBlockLeft,
.FlightInfoBlockLeft {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.FlightBlockRight,
.FlightInfoBlockRight {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.ClearDiv {
  clear: both;
}

.datepickerContainer {
  margin-right: 4px;
  margin-bottom: 4px;
}

.LeftCarrierImg {
  text-align: left;
  margin-bottom: 20px;
}

.LeftCarrierImg img {
  width: 100px;
  vertical-align: middle;
  margin-right: 4px;
}

.StopsInfoDiv, .TransfersSpan {
  color: #950000;
}

.OldPriceSpan {
  color: #828181;
  font-size: 11pt;
  text-decoration: line-through;
}

.StopsInfoDiv {
  font-size: 8pt;
}

.LeftCarrierImgCentered {
  text-align: center;
  margin-bottom: 20px;
}

.LeftCarrierImgCentered img {
  width: 100px;
  vertical-align: middle;
  margin-right: 4px;
}

.LeftInfoTd {
  text-align: right;
}

.MidInfoTd {
  text-align: center;
}

.RightInfoTd {
  text-align: left;
}

.FlightInfoTable {
  width: 100%;
}

.FlightBlockTable {
  width: 100%;
}

.OperatingInfoSpan {
  font-size: 6pt;
  margin-right: 4px;
  margin-left: 4px;
}

.TransfersSpan {
  font-size: 8pt;
  margin-right: 4px;
  margin-left: 4px;
}

.OperatingTD {
  font-size: 9pt;
}

.SmallText {
  font-size: 9pt;
}

.VerySmallText {
  font-size: 6pt;
}

.FlightTime {
  font-size: 11pt;
  font-weight: 700;
  margin-bottom: 6px;
  display: inline-block;
}

.FlightDateLeft,
.FlightDateRight {
  font-size: 11pt;
  font-weight: 700;
  margin-bottom: 6px;
}

.FlightDateLeft {
  margin-right: 4px;
}

.FlightDateRight {
  margin-left: 4px;
}

.FlightAirportNameSpan {
  font-size: 11pt;
  margin-left: 4px;
}

.FlightCode,
.FlightCodeSpan {
  font-size: 12pt;
  font-weight: 400;
  margin-bottom: 6px;
}

.FlightCodeSpan {
  margin-right: 4px;
}

.FlightAirport {
  font-size: 9pt;
  font-weight: 400;
  margin-bottom: 6px;
}

.Airplane {
  margin: auto;
  width: 32px;
  height: 32px;
  background-image: url("img/airplane.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.AirplaneSpan {
  font-size: 10pt;
  display: block;
  width: 200px;
  height: 48px;
  background-image: url("img/airplane.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.AircraftSpan {
  font-size: 10pt;
}

.TicketPrice {
  text-align: center;
  font-weight: 700;
  font-size: 12pt;
  margin-top: 6px;
}

.ViewFlightBtn {
  width: 100%;
  background: rgb(68, 108, 107);
  color: white;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  border: 2px solid rgb(68, 108, 107);
  border-radius: 2px;
  font-size: 12pt;
  height: 40px;
  line-height: 16px;
  outline: none;
  box-shadow: 0 0 8px rgba(26, 58, 57, 0.539);
  margin: 10px auto;
}

.CheckoutBtn {
  width: 100%!important;
  margin-bottom: 4px;
}

.PayPalBtnsContainer {
  margin: 10px auto auto;
}

.FlightInfoBlock {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.486);
  border-radius: 2px;
  margin-bottom: 10px;
  padding-top: 20px;
  margin-top: -30px;
  z-index: -1;
}

.TdSpacer {
  height: 14px;
  width: 100%;
}

.FlightInfoDurationTd {
  border-right: 2px solid rgba(68, 108, 107, 0.5);
  text-align: center;
  padding: 10px;
  width: 50px;
  position: relative;
}

.FlightDurationTdDecoratorTop,
.FlightDurationTdDecoratorBottom {
  position: absolute;
  right: -8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid rgba(68, 108, 107, 0.5);
}

.FlightDurationTdDecoratorTop {
  top: -13px;
}

.FlightDurationTdDecoratorBottom {
  bottom: -13px;
}

.FlightInfoDataTd {
  padding: 10px 10px 10px 20px;
}

.FlightInfoBreak {
  display: inline-block;
  padding: 10px;
  border: 1px solid rgb(68, 108, 107);
  border-radius: 2px;
  margin-left: 70px;
  font-size: 10pt;
  margin-top: 28px;
  margin-bottom: 16px;
}

.FlightInfoAirplane {
  text-align: left;
  width: 250px;
}

.HorizontalSpacerSpan {
  margin-left: 6px;
  margin-right: 10px;
}

.TripDurationTextSpan {
  font-size: 10pt;
  font-weight: 600;
  margin-right: 4px;
}

.DepartArriveTextSpan {
  font-size: 10pt;
  margin-right: 4px;
  font-weight: 600;
}

.Ring {
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.686);
  background: linear-gradient(rgb(81, 169, 158), rgb(68, 108, 107));
  animation: animate 1.2s linear infinite;
  margin: 15px auto 20px;
}

.TravelerDataTable {
  width: 100%;
}

.Ring span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(rgb(81, 169, 158), rgb(68, 108, 107));
}

.Ring span:nth-child(1) {
  filter: blur(5px);
}

.Ring span:nth-child(2) {
  filter: blur(10px);
}

.Ring span:nth-child(3) {
  filter: blur(25px);
}

.Ring span:nth-child(4) {
  filter: blur(50px);
}

.Ring:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: rgba(241, 241, 241, 0.15);
  border: solid rgba(255, 255, 255, 0.3) 8px;
  border-radius: 50%;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

.SendTicketModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
}

.SendTicketForm {
  width: 100%;
  box-sizing: border-box;
  margin: 30vh auto auto;
  text-align: center;
  color: white;
}

.SendTicketInfo {
  font-size: 11pt;
  margin: 20px;
}

.EmailInput {
  border: 2px solid rgb(68, 108, 107);
  border-radius: 2px;
  font-size: 12pt;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  box-shadow: 0 0 8px rgba(26, 58, 57, 0.539);
  width: 350px;
  margin: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.SendTicketBtn {
  display: block;
  background: rgb(68, 108, 107);
  color: white;
  cursor: pointer;
  border-radius: 2px;
  font-size: 12pt;
  margin: auto;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid rgb(68, 108, 107);
  width: 350px;
}

.BlankLink {
  text-decoration: none;
  color: #ffffff;
}

.EmailSentAlert {
  position: fixed;
  z-index: 1000;
  top: 40vh;
  margin-left: 30vw;
  color: rgb(68, 108, 107);
  border: 4px dashed rgb(68, 108, 107);
  padding: 40px;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 70px rgba(26, 58, 57, 1);
  text-align: center;
  width: 40vw;
}

.CountryFlag {
  width: 15px;
  height: 10px;
  outline: none;
  margin-right: 4px;
}